import React, { useState, useEffect } from 'react'
import './Protected.scss'
import axios from 'axios'
import { CSSTransition } from 'react-transition-group';

import Header from '../Header/Header'
import AddNews from '../AddNews/AddNews'
import AddNewsForm from '../AddNewsForm/AddNewsForm'
import Notes from '../Notes/Notes'
import AllNews from '../AllNews/AllNews'
import Footer from '../Footer/Footer'

const Protected = ({ history }) => {
  const [showButton, setShowButton] = useState(true)
  const [formOpen, setFormOpen] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [auth, setAuth] = useState('')
  const [data, setData] = useState([
    { id: '1', img: '', label: 'Loading', text: '' },
    { id: '2', img: '', label: 'Loading', text: '' },
    { id: '3', img: '', label: 'Loading', text: '' },
    { id: '4', img: '', label: 'Loading', text: '' }
  ])

  useEffect( () => {
    const fetchData = async () => {
      let result = await axios.post('/news')
      setData(result.data)
    }
    fetchData()
  }, [refresh])

  return (
    <>
      <Header history={history} />
      <main>
        <AddNews formOpen={setFormOpen} showButton={showButton} />
        <CSSTransition
          in={formOpen}
          timeout={300}
          classNames="formOpen"
          unmountOnExit
          onEnter={ () => setShowButton(false) }
          onExited={ () => setShowButton(true) }
        >
          <AddNewsForm formOpen={setFormOpen} refresh={refresh} setRefresh={setRefresh} auth={auth} /> 
        </CSSTransition>
        <Notes />
        <input type="password" placeholder="auth" onChange={event => setAuth(event.target.value)} />
      </main>
      <AllNews data={data} setData={setData} auth={auth} />
      <Footer />
    </>
  )
}

export default Protected