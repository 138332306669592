import React, { useState } from 'react'
import './App.scss'
import { BrowserRouter as Router, Route } from "react-router-dom"

import Auth from './Auth/Auth'
import Protected from './Protected/Protected'
import PrivateRoute from './Protected/PrivateRoute'


const App = () => {
  const [protect, setProtect] = useState(false)
  return (
    <Router>
      {/* <Route exact path="/" component={Protected} /> */}
      <Route exact path="/" render={ () => <Auth protect={setProtect} /> } />
      <PrivateRoute path="/protected" auth={protect} component={Protected} />
    </Router>
  )
}

export default App
