import React from 'react'
import './Notes.scss'


const Notes = () => {
  return (
    <div className="notes">
      <span> ! </span>
      Optimize your images
      <a href="https://squoosh.app/" target="blank"> squoosh </a>
    </div>
  )
}

export default Notes