import React, { useState } from 'react'
import './AddNewsForm.scss'
import axios from 'axios'

import { ReactComponent as SvgCensel } from '../../assets/icon/cencel.svg'

const AddNewsForm = ({ formOpen, refresh, setRefresh, auth }) => {
  const [file, setFile] = useState({
    selectedFile: null,
    prevFile: null,
    chooseFile: 'Choose image'
  })
  const [label, setLabel] = useState('Label')
  const [text, setText] = useState('Text')
  const [message, setMessage] = useState('')
  const [openNews, setOpenNews] = useState(false)


  const handlChangedFile = (event) => { 
    setFile({
      selectedFile: event.target.files[0],
      prevFile: URL.createObjectURL(event.target.files[0]) || null,
      chooseFile: event.target.files[0].name
    })
  }

  // const onUploadProgress = (progressEvent) => {
  //   setMessage(progressEvent.loaded / progressEvent.total)
  // }

  const hendelSubmit = (event) => {
    event.preventDefault()
    let fileName = `${Date.now()}-${file.selectedFile.name}`
    let formData = new FormData()
        formData.append('myFile', file.selectedFile, fileName)
        formData.append('label', label)
        formData.append('text', text)
        formData.append('fileName', fileName)
        formData.append('auth', auth)

    axios.post('/addNews', formData)
    .then( (res) => { 
      setMessage(res.data.msg)
      setRefresh(!refresh)
    })
    .catch( (err) => { setMessage(err.message) })
  }

  return (
    <div className="addForm">
      <SvgCensel onClick={() => formOpen(false)} />
      <section>
        <form onSubmit={hendelSubmit} >
          <label>
            {file.chooseFile}
            <input
              type="file"
              accept="image/*"
              onChange={handlChangedFile}
              required
            />
          </label>
          <input
            placeholder="Label"
            type="text"
            onChange={(event) => setLabel(event.target.value)}
            required
          />
          <textarea 
            placeholder="Text"
            onChange={(event) => setText(event.target.value)}
            required
          >
          </textarea>
          <span> {message} </span>
          <button type="submit"> SAVE </button>
        </form>
        <div className={openNews ? 'openNews' : null} onClick={() => setOpenNews(!openNews)}>
					<img src={file.prevFile} alt=""/> 
          <h5> {label} </h5>
          <p> {text} </p>
        </div>
      </section>
    </div>
  )
}

export default AddNewsForm