import React from 'react'
import './Header.scss'


const Header = ({ history }) => {
  return (
    <header>
      <button onClick={ () => { history.push("/") } }> Sign Out </button>
    </header>
  )
}

export default Header