import React from 'react'
import './Footer.scss'


const Footer = () => {

  return (
    <footer>
      
    </footer>
  )
}

export default Footer