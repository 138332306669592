import React from 'react'
import './AddNews.scss'

import { ReactComponent as SvgEdit } from '../../assets/icon/edit.svg'

const AddNews = ({ formOpen, showButton }) => {
  return (
    <div className={showButton ? "addNews" : "addNews-hide"} onClick={ () => formOpen(true) }>
      <SvgEdit />
      <h3>Add News</h3>
    </div>
  )
}

export default AddNews