import React, { useState } from 'react'
import './AllNews.scss'
import axios from 'axios'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const AllNews = ({ data, setData, auth }) => {
  const [activeNews, setActiveNews] = useState(null)
  const [offset, setOffset] = useState(4)

  const activeElement = (index) => { 
    activeNews === index ? setActiveNews(null) : setActiveNews(index) 
  }

  const deletNews = async (id, pathImg) => {
    let result = await axios.post('/deletNews', { 
      id: id,
      pathImg: pathImg,
      auth: auth
    })
    if (result.data.msg === 'success') {     
      setActiveNews(null)
      setData( (data) => data.filter( (item) => item.id !== id ) ) 
      setOffset(offset - 1)
    } else { alert(result.data.msg) }
  }

  const loadMore = async () => {
    let res = await axios.post('/loadMore', { offset: offset })
    setOffset(offset + 4)
    setData( (data) => [...data, ...res.data] )
  }

  return (
    <>
      <TransitionGroup className="allNews">
        { data.map( (item, index) =>
          <CSSTransition
            key={item.id}
            timeout={500}
            classNames="item"
          >
            <div className={index === activeNews ? 'openNews' : null} onClick={() => activeElement(index)}>
              <button onClick={() => deletNews(item.id, item.img)}> DELETE </button>
		  	    	<img src={item.img} alt=""/> 
              <h5> {item.label} </h5>
              <p> {item.text} </p>
            </div>
          </CSSTransition>
        ) }
      </TransitionGroup>
      <button className="loadButton" onClick={() => loadMore()}>Load more</button>
    </>
  )
}

export default AllNews