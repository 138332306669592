import React, { useState } from 'react'
import './Auth.scss'
import axios from 'axios'
import { Redirect } from "react-router-dom";

const Auth = (props) => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [redirect, setRedirect] = useState(false)

  const hendelSubmit = (event) => {
    event.preventDefault()
    axios.post('/auth', {
      name: name,
      password: password
    })
    .then( (res) => {
      if (res.data.msg === true) { 
        setMessage('Welcome') 
        props.protect(true)
        setRedirect(true)
      } 
      if (res.data.msg === false) { setMessage('Fail') }
    })
    .catch( (err) => { setMessage(err.messag) } )
  }

  if (redirect) return <Redirect to="/protected" />
  
  return (
    <div className="form-wrap">
      <div> Sign In </div>
      <form onSubmit={hendelSubmit}>
        <input
          placeholder="Username"
          type="text"
          onChange={event => setName(event.target.value)}
          required
        />
        <input
          placeholder="Password"
          type="password"
          onChange={event => setPassword(event.target.value)}
          required
        />
        <button type="submit"> SIGN IN </button>
        <span> {message} </span>
        <span> Have a nice work! </span>
      </form>
    </div> 
  )
}

export default Auth